exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bridesmaid-dresses-js": () => import("./../../../src/pages/bridesmaid-dresses.js" /* webpackChunkName: "component---src-pages-bridesmaid-dresses-js" */),
  "component---src-pages-discounts-js": () => import("./../../../src/pages/discounts.js" /* webpackChunkName: "component---src-pages-discounts-js" */),
  "component---src-pages-formal-dresses-js": () => import("./../../../src/pages/formal-dresses.js" /* webpackChunkName: "component---src-pages-formal-dresses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maxi-dresses-js": () => import("./../../../src/pages/maxi-dresses.js" /* webpackChunkName: "component---src-pages-maxi-dresses-js" */),
  "component---src-pages-midi-dresses-js": () => import("./../../../src/pages/midi-dresses.js" /* webpackChunkName: "component---src-pages-midi-dresses-js" */),
  "component---src-pages-mini-dresses-js": () => import("./../../../src/pages/mini-dresses.js" /* webpackChunkName: "component---src-pages-mini-dresses-js" */),
  "component---src-pages-new-dresses-js": () => import("./../../../src/pages/new-dresses.js" /* webpackChunkName: "component---src-pages-new-dresses-js" */),
  "component---src-pages-party-dresses-js": () => import("./../../../src/pages/party-dresses.js" /* webpackChunkName: "component---src-pages-party-dresses-js" */),
  "component---src-pages-sales-dresses-js": () => import("./../../../src/pages/sales-dresses.js" /* webpackChunkName: "component---src-pages-sales-dresses-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-trending-all-dresses-js": () => import("./../../../src/pages/trending-all-dresses.js" /* webpackChunkName: "component---src-pages-trending-all-dresses-js" */),
  "component---src-pages-trending-bridesmaid-dresses-js": () => import("./../../../src/pages/trending-bridesmaid-dresses.js" /* webpackChunkName: "component---src-pages-trending-bridesmaid-dresses-js" */),
  "component---src-pages-trending-formal-dresses-js": () => import("./../../../src/pages/trending-formal-dresses.js" /* webpackChunkName: "component---src-pages-trending-formal-dresses-js" */),
  "component---src-pages-trending-js": () => import("./../../../src/pages/trending.js" /* webpackChunkName: "component---src-pages-trending-js" */),
  "component---src-pages-trending-maxi-dresses-js": () => import("./../../../src/pages/trending-maxi-dresses.js" /* webpackChunkName: "component---src-pages-trending-maxi-dresses-js" */),
  "component---src-pages-trending-midi-dresses-js": () => import("./../../../src/pages/trending-midi-dresses.js" /* webpackChunkName: "component---src-pages-trending-midi-dresses-js" */),
  "component---src-pages-trending-mini-dresses-js": () => import("./../../../src/pages/trending-mini-dresses.js" /* webpackChunkName: "component---src-pages-trending-mini-dresses-js" */),
  "component---src-pages-trending-party-dresses-js": () => import("./../../../src/pages/trending-party-dresses.js" /* webpackChunkName: "component---src-pages-trending-party-dresses-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

